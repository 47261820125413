import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Formats a number to a specified number of decimal places.
 * If a threshold is provided, the number will be formatted with no decimal places if it is greater than or equal to the threshold.
 *
 * @param value - The number to format.
 * @param fractionDigits - The number of decimal places to include in the formatted number.
 * @param threshold - An optional threshold above which the number will be formatted with no decimal places.
 * @returns The formatted number as a string.
 */
export const formatNumber = (value: number, fractionDigits = 0, threshold?: number): string => {
  if (threshold === undefined) {
    return value.toFixed(fractionDigits);
  }

  if (value >= threshold) {
    return value.toFixed(0);
  }

  return value.toFixed(fractionDigits);
};
